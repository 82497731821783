import { Component, Vue } from "vue-property-decorator";
import { walletModule } from "@store/namespaces";
import { WalletGetters } from "@store/modules/wallet/types";

@Component({
  components: {
    DesktopHeaderWallets: () =>
      import("./desktop/DesktopHeaderWallets/DesktopHeaderWallets.vue"),
    MobileHeaderWallets: () =>
      import("./mobile/MobileHeaderWallets/MobileHeaderWallets.vue"),
  },
})
export default class HeaderWallets extends Vue {
  @walletModule.Getter("walletsList")
  private readonly walletsListGetter!: WalletGetters["walletsList"];

  private get isDense() {
    if (
      this.$vuetify.breakpoint.width < 1850 &&
      this.walletsListGetter.length > 2
    ) {
      return true;
    }

    return this.walletsListGetter.length > 3 || this.$vuetify.breakpoint.mobile;
  }
}
